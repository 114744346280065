import './contact.scss';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import _ from 'underscore';
import CONFIG from '../../../common/config';
import API from '../../../common/constants/api.constants';
import CONTACT from '../../../common/constants/contact.constants';
import ROUTES from '../../../common/constants/routes.constants';
import { KiaContext } from '../../../common/context/main.context';
import { makeContact } from '../../../common/services/contact.service';
import { getDealers } from '../../../common/services/dealers.service';
import GTM from '../../../common/services/gtm.service';
import querystring from '../../../common/utils/querystring.utils';
import Button from '../../01_atoms/button/Button';
import Input from '../../01_atoms/form/Input';
import Select from '../../01_atoms/form/Select';
import Icon from '../../01_atoms/icons/Icon';
import Info from '../../02_molecules/info/Info';
import Loading from '../../02_molecules/loading/Loading';
import { PromoOffer } from '../../02_molecules/promo/PromoOffer';
import Page from '../../04_layouts/page/Page';

const CONTACTMODEL = {
  address_country: '',
  car_model: '',
  gender: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  company: '',
  dealer: '',
  dealer_code: '',
  location: '',
  language: '',
  source: '',
  keep_informed: 0,
  consent_statement: '',
  campaign_code: '',
  is_b2c: 0,
};

const autocompleteService = CONFIG.WOOSMAP_KEY ? new window.woosmap.localities.AutocompleteService(CONFIG.WOOSMAP_KEY) : null;

export default function Contact() {
  const {
    currentLocale,
    prefix,
    dealer,
    clearDealer,
    cars,
    isB2c,
    country,
    showPromo,
  } = useContext(KiaContext);
  const location = useLocation();

  const history = useHistory();
  const params = useParams();
  const form = useRef();
  const voucherLink = useRef();

  const intl = useIntl();

  // @todo check in feb to update to better solution
  const month = new Date().getMonth() + 1;
  let day = new Date().getDate();
  let showOther = month === 2;

  if (country.id === 'lu' && day < 4) {
      showOther = false;
  }

  let voucherUrl = intl.formatMessage({ id: 'promo.contact.voucherlink' });
  if (showOther) {
    voucherUrl = intl.formatMessage({ id: 'promo.contact.voucherlink.feb' });
  }

  const [ source, setSource ] = useState(params.source);
  const [ selectedDealer, setSelectedDealer ] = useState(dealer);
  const [ dealers, setDealers ] = useState([]);
  const [ valid, setValid ] = useState(false);
  const [ sending, setSending ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ failed, setFailed ] = useState(false);
  const [ predictions, setPredictions ] = useState([]);
  const [ prediction, setPrediction ] = useState(null);
  const [ carPlaceholderShown, setCarPlaceholderShown ] = useState(false);
  const [ genderPlaceholderShown, setGenderPlaceholderShown ] = useState(false);
  const [ isDesktop, setIsDesktop ] = useState(false);
  const mediaQuery = '(min-width: 1200px)';

  const CARS = [
    { value: 'Ceed', label: intl.formatMessage({ id: 'contact.cars.ceed' }), gtm: 'ceed-gt-line', model: 'ceed', visual: 'ceed' },
    { value: 'Ceed Sportswagon', label: intl.formatMessage({ id: 'contact.cars.ceedsw' }), gtm: 'ceed-sw-business-line', model: 'ceed-sw', visual: 'ceed-sw' },
    { value: 'Ceed Sportswagon PHEV', label: intl.formatMessage({ id: 'contact.cars.ceedswphev' }), gtm: 'ceed-sw-business-line', model: '', visual: 'ceed-sw'},
    { value: 'Niro HEV', label: intl.formatMessage({ id: 'contact.cars.nirohev' }), gtm: 'e-niro-e-xtra', model: '' , visual: 'niro'},
    { value: 'Niro PHEV', label: intl.formatMessage({ id: 'contact.cars.nirophev' }), gtm: 'e-niro-e-xtra', model: '', visual: 'niro' },
    { value: 'Niro EV', label: intl.formatMessage({ id: 'contact.cars.niroev' }), gtm: 'e-niro-e-xtra', model: 'niro', visual: 'niro' },
    { value: 'Picanto', label: intl.formatMessage({ id: 'contact.cars.picanto' }), gtm: 'picanto-gt-line', model: 'picanto', visual: 'picanto' },
    { value: 'ProCeed', label: intl.formatMessage({ id: 'contact.cars.proceed' }), gtm: 'proceed-gt', model: 'proceed', visual: 'proceed' },
    // { value: 'Rio', label: intl.formatMessage({ id: 'contact.cars.rio' }), gtm: 'Rio', model: 'rio', visual: 'rio' },
    { value: 'Sorento', label: intl.formatMessage({ id: 'contact.cars.sorento' }), gtm: 'sorento-more', model: 'sorento', visual: 'sorento' },
    { value: 'Sorento HEV', label: intl.formatMessage({ id: 'contact.cars.sorentohev' }), gtm: 'sorento-more', model: '', visual: 'sorento' },
    // { value: 'Sorento PHEV', label: intl.formatMessage({ id: 'contact.cars.sorentophev' }), gtm: 'sorento-more', model: '', visual: 'sorento' },
    // { value: 'e-Soul', label: intl.formatMessage({ id: 'contact.cars.esoul' }), gtm: 'e-soul-more', model: 'e-soul', visual: 'e-soul' },
    { value: 'Sportage', label: intl.formatMessage({ id: 'contact.cars.sportage' }), gtm: 'sportage', model: 'sportage', visual: 'sportage' },
    { value: 'Sportage HEV', label: intl.formatMessage({ id: 'contact.cars.sportagehev' }), gtm: 'sportage', model: '', visual: 'sportage' },
    { value: 'Sportage PHEV', label: intl.formatMessage({ id: 'contact.cars.sportagephev' }), gtm: 'sportage', model: '', visual: 'sportage' },
    // { value: 'Stinger', label: intl.formatMessage({ id: 'contact.cars.stinger' }), gtm: 'stinger-gt', model: 'stinger', visual: 'stinger' },
    { value: 'Stonic', label: intl.formatMessage({ id: 'contact.cars.stonic' }), gtm: 'stonic-gt-line', model: 'stonic', visual: 'stonic' },
    { value: 'XCeed', label: intl.formatMessage({ id: 'contact.cars.xceed' }), gtm: 'xceed-business-line', model: 'xceed', visual: 'xceed' },
    { value: 'XCeed PHEV', label: intl.formatMessage({ id: 'contact.cars.xceedphev' }), gtm: 'xceed-business-line', model: '', visual: 'xceed' },
    { value: 'EV6', label: intl.formatMessage({ id: 'contact.cars.ev6' }), gtm: 'ev6', model: 'ev6', visual: 'ev6' },
    { value: 'EV9', label: intl.formatMessage({ id: 'contact.cars.ev9' }), gtm: 'ev9', model: 'ev9', visual: 'ev9' },
    { value: 'EV3', label: intl.formatMessage({ id: 'contact.cars.ev3' }), gtm: 'ev3', model: 'ev3', visual: 'ev3' },
  ];

  const STINGER = CARS.find(car => car.value === 'Stinger');
  const EV9 = CARS.find(car => car.value === 'EV9');
  const EV3 = CARS.find(car => car.value === 'EV3');

  let _model;
  if (location.search) {
    const search = querystring.decode(location.search);

    if (search.model) {
      _model = search.model;
    }
  }
  const [ model, setModel ] = useState(_model);
  const [ formData, setFormData ] = useState({ ...CONTACTMODEL, is_b2c: isB2c ? 1 : 0 });
  let car = cars.find((car) => car.slug === CARS.find(car => car.value === formData.car_model)?.visual);

  const showBackgroundImage = car?.image && isDesktop;

  const genders = [
    {
      value: '',
      label: intl.formatMessage({ id: 'gender.title' }),
    },
    {
      value: 'M',
      label: intl.formatMessage({ id: 'gender.male' }),
    },
    {
      value: 'F',
      label: intl.formatMessage({ id: 'gender.female' }),
    },
  ];

  const carsOptions = [
    {
      value: '',
      label: intl.formatMessage({ id: 'contact.cars.select' }),
    },
    ...CARS.map(car => ({ value: car.value, label: car.label }))
  ];

  const [ filteredCars, setFilteredCars ] = useState([...carsOptions]);

  // @todo: remove when ev3 testdrive is available
  const filterCars = () => {
    console.log('filter cars');
    if (source === CONTACT.testdrive) {
      setFilteredCars(carsOptions.filter(car => car.value !== EV3.value));
    } else {
      setFilteredCars([...carsOptions]);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "car_model") {
      if (value === "") setCarPlaceholderShown(true);
      if (value !== "") setCarPlaceholderShown(false);

      if (value === EV9.value) {
        resetDealer();
      }
    }
    if (name === "gender") {
      if (value === "") setGenderPlaceholderShown(true);
      if (value !== "") setGenderPlaceholderShown(false);
    }

    setFormData(_formData => ({
      ..._formData,
      [name]: value,
    }));

    setSuccess(false);
    setFailed(false);
  };

  const onSourceChange = (e) => {
    const { value, dataset: { route } } = e.target;

    setSource(value);
    const code = CONTACT.codes[value];

    if (car && car.slug === EV3.model && value === CONTACT.testdrive) {
      formData.car_model = '';
    }
    filterCars();

    onChange(e);

    const trackPayload = {
      dlCategory: 'Form',
      dlAction: 'Toggle',
      dlLabel: value,
    };

    if (formData.car_model) {
      trackPayload.dlModel = formData.car_model;
    }

    GTM.track(trackPayload);

    const newFormData = {
      ...formData,
      source: CONTACT.values[value],
      campaign_code: code,
    }

    if (value === CONTACT.testdrive) delete newFormData.trade_in
    if (value === CONTACT.tradein) newFormData.trade_in = 1
    if (value === CONTACT.quote) newFormData.trade_in = 0

    setFormData(newFormData)

    history.replace(route);
  };

  const getPredictions = (payload) => {
    try {
      if (CONFIG.DEBUG) { console.log('get predictions', payload); }

      autocompleteService.getQueryPredictions({
        input: payload.value,
        components: {
          "country": [currentLocale.country_iso],
        },
        language: currentLocale.slug
      }, (response) => {
        setPredictions(response.localities);
      }, (errorCode, errorText) => {
        if (CONFIG.DEBUG) { console.error(errorCode, errorText); }
      });
    } catch(e) {
      if (CONFIG.DEBUG) { console.error(e); }
    }
  };

  const debouncedgetPredictions = useCallback(_.debounce(getPredictions, 300, false), []);

  const onLocationChange = (e) => {
    const { value } = e.target;

    if (CONFIG.DEBUG) { console.log('location change', value); }
    onChange(e);

    if (selectedDealer || dealers.length) {
      setSelectedDealer(null);
      setDealers([]);
      setPrediction('');
      setPredictions([]);

      setFormData({
        ...formData,
        dealer: '',
        dealer_code: '',
        location: value,
      });
    }

    if (!value || value.length < 2) {
      setPredictions([]);
      setPrediction('');
      return;
    }

    debouncedgetPredictions({ value });
  };

  const onPredictionClick = (prediction) => {
    setFormData({
      ...formData,
      location: prediction.description,
    });

    setPrediction(prediction);
    setPredictions([]);
  };

  const getDealersFromLocation = async (location) => {
    if (CONFIG.DEBUG) { console.log('get dealers', location); }

    const response = await getDealers({
      ...location,
    });

    if (CONFIG.DEBUG) { console.log('received dealers', response.features); }

    let { features } = response;

    if (formData.car_model && STINGER && formData.car_model === STINGER.value) {
      features = [...features].filter(feature => feature.properties.types.indexOf('stinger') >= 0);
      if (CONFIG.DEBUG) { console.log('limit only stinger centers', model, response.features, features); }
    }

    if (formData.car_model && EV9 && formData.car_model === EV9.value) {
      features = [...features].filter(feature => !feature.properties.types.includes('hide_ev9'));
      if (CONFIG.DEBUG) { console.log('limit only EV9 resellers', formData.car_model, features); }
    }

    const dealers = features.slice(0, CONFIG.MAX_DEALERS);

    if (CONFIG.DEBUG) { console.log('limit dealers', dealers); }

    setDealers(dealers);
  };

  const onDealerClick = (dealer) => {
    setSelectedDealer(dealer);
  };

  const onCheck = (e) => {
    const { name, checked } = e.target;

    setFormData({
      ...formData,
      [name]: checked ? 1 : 0,
    });

    setSuccess(false);
    setFailed(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { target } = e;

    setValid(false);
    setSending(true);

    const data = {
      ...formData,
    };

    if (data.car_model === 'Sorento1' || data.car_model === 'Sorento2') {
      data.car_model = 'Sorento';
    }

    if (CONFIG.DEBUG) { console.log('submit form', target.checkValidity(), data); }

    if (target.checkValidity()) {
      try {
        const response = await makeContact(data);

        if (response.status === API.success) {

          const gtm_model = CARS.find(car => car.value === formData.car_model) || {};

          GTM.track({
            dlCategory: 'Form',
            dlAction: 'Submit',
            dlLabel: source,
            dlModel: gtm_model.gtm,
            dlFormCarModel: formData.car_model,
          });

          // reset();

          setSelectedDealer('');
          setDealers([]);

          setSuccess(true);
        } else {
          console.error('set errors', response);
          setFailed(true);
        }

      } catch(e) {
        console.error('set general errors', e);
        setFailed(true);
      }
    }

    setSending(false);
  };

  const resetDealer = () => {
    setFormData(_formData => ({
      ..._formData,
      dealer: '',
      dealer_code: '',
      location: '',
    }));
    setSelectedDealer(null);
    setDealers([]);
    setPrediction(null);
    setPredictions([]);

    clearDealer();
  };

  const reset = () => {
    const _source = CONTACT.values[params.source];
    const _code = CONTACT.codes[params.source];

    const payload = {
      ...CONTACTMODEL,
      source: _source,
      language: currentLocale.language,
      address_country: currentLocale.country,
      campaign_code: _code,
      consent_statement: intl.formatMessage({ id: 'contact.optin' }),
      is_b2c: isB2c ? 1 : 0,
    };

    if (params.source === CONTACT.tradein) payload.trade_in = 1
    if (params.source === CONTACT.quote) payload.trade_in = 0

    if (location.search) {
      const search = querystring.decode(location.search);

      if (search.model) {
        setModel(search.model);

        const carsearch = CARS.find(car => car.model === search.model);

        if (carsearch) {
          payload.car_model = carsearch.value;
        }
      }
    }

    setFormData(payload);

    // setSource(_source);
    setSending(false);
    setFailed(false);
    setSuccess(false);
    setValid(false);

  };

  const goBack = () => {
    history.goBack();
  }

  useEffect(() => {
    if (selectedDealer) {
      const { properties: { name, user_properties: { sap_dealer_code } } } = selectedDealer;

      setFormData({
        ...formData,
        dealer: name,
        dealer_code: sap_dealer_code,
      });
    }
  }, [selectedDealer]);

  useEffect(() => {
    if (!prediction) { return; }

    getDealersFromLocation(prediction.location);
  }, [prediction]);

  useEffect(() => {
    setValid(form.current.checkValidity() && formData.dealer);
  }, [ formData ]);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const media = window.matchMedia(mediaQuery)
    if (media.matches !== isDesktop) {
      setIsDesktop(media.matches)
    }
    const handleChange = () => {
      setIsDesktop(media.matches)
    }
		media.addEventListener("change", handleChange)
		return () => media.removeEventListener("change", handleChange)
	}, [isDesktop, mediaQuery, setIsDesktop])

  useEffect(() => {
    if (!success) { return; }
    if (!voucherLink?.current) { return; }

    voucherLink.current.click();
  }, [success, voucherLink]);

  useEffect(() => {
    filterCars();
  }, [source]);

  return (
    <>
      { showBackgroundImage && <style scoped>{`
        .page--contact {
          background-image: url(${car.image});
          background-repeat: no-repeat;
          background-size: 150%;
          background-position-y: -45vh;
          background-attachment: fixed;
        }

        .page--contact .page__inner {
          margin-top: 90px;
        }

        .contact__wrapper {
          margin-left: 15%;
          margin-top: -20px;
        }

        .contact__form {
          background: var(--color--neutrals-0);
          padding: 30px;
        }`}
      </style> }
      <Page
        section
        name="contact"
        chat
        footer
        fixedHeader={showBackgroundImage}
        lightHeader={showBackgroundImage}
        dlLabel={CONTACT[source]}
        dlModel={model}
        className={!showBackgroundImage ? 'dark' : ''}
      >
        <Button mode="naked" color="transparent" onClick={goBack} icon={<Icon icon="arrow-left" />} className="bold button--back" size="small"><FormattedMessage id="back.contact" /></Button>

        <div className="contact__wrapper">
          <form className="contact__form" onSubmit={onSubmit} noValidate ref={form}>
            <div className="contact__radios mb--5">
              <Input type="radio" disabled={sending} id="testdrive" data-route={`${prefix}${ROUTES.testdrive}`} wrapperClassName="contact__radio p--3 bold" value={CONTACT.testdrive} label={<><span><FormattedMessage id="contact.testdrive" /></span></>} name="source" checked={source === CONTACT.testdrive} onChange={onSourceChange} />
              <Input type="radio" disabled={sending} id="quote" data-route={`${prefix}${ROUTES.quote}`} wrapperClassName="contact__radio p--3 bold" value={CONTACT.quote} label={<><span><FormattedMessage id="contact.quote" /></span></>} name="source" checked={source === CONTACT.quote} onChange={onSourceChange} />
              {isB2c && <Input type="radio" disabled={sending} id="tradein" data-route={`${prefix}${ROUTES.tradein}`} wrapperClassName="contact__radio p--3 bold" value={CONTACT.tradein} label={<><span><FormattedMessage id="contact.tradein" /></span></>} name="source" checked={source === CONTACT.tradein} onChange={onSourceChange} />}
            </div>

            { showPromo && <PromoOffer content={'promo.content.form'} showLegal={true} /> }

            { source === CONTACT.testdrive ? (
              <p className="p--1 my--5"><FormattedMessage id="contact.testdrivemessage" /></p>
            ) : null }

            { source === CONTACT.quote ? (
              <>
                <p className="p--1 my--5"><FormattedMessage id="contact.quotemessage" /></p>
              </>
            ) : null }

            { source === CONTACT.tradein ? (
              <p className="p--1 my--5"><FormattedMessage id="contact.tradeinmessage" /></p>
            ) : null }

            <Select
              id="car_model"
              disabled={sending}
              name="car_model"
              wrapperClassName="mb--5"
              value={formData.car_model}
              required
              options={filteredCars}
              onChange={onChange}
              className={carPlaceholderShown ? "placeholder-shown" : ""}
            />
            <h3 className="p--1 bold mb--2"><FormattedMessage id="contact.subtitle.personal" /></h3>
            <Select
              id="gender"
              name="gender"
              wrapperClassName="mb--5"
              options={genders}
              value={formData.gender}
              required
              onChange={onChange}
              errorMessage={ intl.formatMessage({ id: 'contact.gender.error' }) }
              className={genderPlaceholderShown ? "placeholder-shown" : ""}
            />
            <Input
              disabled={sending}
              label={<FormattedMessage id="contact.firstname" />}
              wrapperClassName="mb--5"
              labelAfter
              placeholder={intl.formatMessage({id: 'contact.firstname'})}
              id="first_name"
              name="first_name"
              required
              onChange={onChange}
              value={formData.first_name}
              errorMessage={intl.formatMessage({id: 'contact.firstname.error'})}
            />
            <Input
              disabled={sending}
              label={<FormattedMessage id="contact.lastname" />}
              wrapperClassName="mb--5"
              labelAfter
              placeholder={intl.formatMessage({id: 'contact.lastname'})}
              id="last_name"
              name="last_name"
              required
              onChange={onChange}
              value={formData.last_name}
              errorMessage={intl.formatMessage({id: 'contact.lastname.error'})}
            />
            <Input
              disabled={sending}
              type="email"
              label={<FormattedMessage id="contact.email" />}
              wrapperClassName="mb--5"
              labelAfter
              placeholder={intl.formatMessage({id: 'contact.email'})}
              id="email"
              name="email"
              required
              onChange={onChange}
              value={formData.email}
              errorMessage={intl.formatMessage({id: 'contact.email.error'})}
            />
            <Input
              disabled={sending}
              type="tel"
              label={<FormattedMessage id="contact.phone" />}
              wrapperClassName="mb--5"
              labelAfter
              placeholder={intl.formatMessage({id: 'contact.phone'})}
              id="phone"
              name="phone_number"
              required
              onChange={onChange}
              minLength="5"
              pattern="[+]?[0-9]+"
              value={formData.phone_number}
              errorMessage={intl.formatMessage({id: 'contact.phone.error'})}
            />
            { !isB2c ? (
              <Input
                disabled={sending}
                type="text"
                label={<FormattedMessage id="contact.company" />}
                wrapperClassName="mb--5"
                labelAfter
                placeholder={intl.formatMessage({id: 'contact.company'})}
                id="company"
                name="company"
                onChange={onChange}
                value={formData.company}
                errorMessage={intl.formatMessage({id: 'contact.company.error'})}
              />
            ) : null }

            { !dealer ? (
              <>
                <h3 className="p--1 bold mb--2"><FormattedMessage id="contact.subtitle.dealer" /></h3>
                <Input
                  disabled={sending}
                  type="text"
                  label={<FormattedMessage id="contact.dealer" />}
                  wrapperClassName="mb--5"
                  labelAfter
                  placeholder={intl.formatMessage({ id: 'contact.dealer'})}
                  id="location"
                  name="location"
                  required
                  onChange={onLocationChange}
                  value={formData.location}
                  hasError={formData.location && !selectedDealer}
                  errorMessage={intl.formatMessage({id: 'contact.dealer.error'})}
                >
                  <div className="predictions">
                    { predictions.map((prediction, i) => {
                      return (
                        <div className="prediction" key={`prediction-${i}`} onClick={() => onPredictionClick(prediction)}>
                          { prediction.description }
                        </div>
                      );
                    }) }
                  </div>
                </Input>
              </>
            ) : null }

            { prediction || dealer ? (<h3 className="p--1 bold mb--2"><FormattedMessage id="contact.dealer.title" /></h3>) : null }
            <div className="dealers">
              { selectedDealer ? (
                <div className={`dealer dealer--active`}>
                  <div className="dealer__meta">
                    <p className="p--1 bold mb--2">{ selectedDealer.properties.name }</p>
                    <p className="p--1 mb--0">{ selectedDealer.properties.address.city }</p>
                  </div>
                  { selectedDealer.properties.distance ? (
                    <div className="dealer__distance">
                      <p className="p--1">
                        <FormattedNumber
                          value={Math.ceil(selectedDealer.properties.distance * 100 / 1000) / 100}
                          // eslint-disable-next-line
                          style="unit"
                          unit="kilometer"
                          unitDisplay="narrow"
                        />
                      </p>
                    </div>
                  ) : null }
                </div>
              ) : dealers.map((dealer, i) => (
                <div className={`dealer${selectedDealer && selectedDealer.properties.user_properties.sap_dealer_code === dealer.properties.user_properties.sap_dealer_code ? ' dealer--active' : ''}`} onClick={() => onDealerClick(dealer)} key={`${dealer}-${dealer.properties.store_id}-${i}`}>
                  <div className="dealer__meta">
                    <p className="p--1 bold mb--2">{ dealer.properties.name }</p>
                    <p className="p--1 mb--0">{ dealer.properties.address.city }</p>
                  </div>
                  <div className="dealer__distance">
                    <p className="p--1">
                      <FormattedNumber
                        value={Math.ceil(dealer.properties.distance * 100 / 1000) / 100}
                        // eslint-disable-next-line
                        style="unit"
                        unit="kilometer"
                        unitDisplay="narrow"
                      />
                    </p>
                  </div>
                </div>
              )) }
            </div>

            { selectedDealer ? (<Button onClick={() => resetDealer()} mode="naked" size="small" underline><FormattedMessage id="contact.dealer.reset" /></Button>) : null }

            { !isB2c || source === CONTACT.testdrive ? null : <Input disabled={sending} type="checkbox" id="trade_in" name="trade_in" wrapperClassName="mt--3" label={<FormattedMessage id="contact.tradeincheck" />} onChange={onCheck} checked={formData.trade_in || 0} />}

            <p className="p--3 fc--text-legal"><FormattedMessage id="contact.legal" values={{ a: chunks => (<a href={intl.formatMessage({ id: 'footer.legal.privacy.link' })} target="_blank" rel="noopener noreferrer">{ chunks }</a>) }} /></p>
            <p className="p--3 fc--text-legal"><FormattedMessage id="contact.optin.legal" /></p>

            <Input disabled={sending} type="checkbox" id="keep_informed" name="keep_informed" label={<FormattedMessage id="contact.optin" />} onChange={onCheck} checked={formData.keep_informed} />

            <div className="form__actions mt--4">
              <Button type="submit" disabled={!valid || sending}><FormattedMessage id={source === CONTACT.testdrive ? "contact.submit.testdrive" : "contact.submit"} /></Button>
            </div>

            { success ? (
              <Info page visible className="center">
                <h4 className="heading heading--4 mb--2"><FormattedMessage id="contact.successtitle" /></h4>
                <p className="p--2 mt--0 mb--2"><FormattedMessage id="contact.successmessage" /></p>
                { source === CONTACT.quote && showPromo ? <p className="p--2 mt--0 mb--2"><FormattedMessage id="promo.contact.vouchertext" values={{ voucher: (<a href={voucherUrl} target="_blank" rel="noopener noreferrer" ref={voucherLink} download="Kia voucher">{ intl.formatMessage({ id: 'promo.contact.voucherlabel' }) }</a>) }} /></p> : null }
                <Button size="small" color="primary" className="success__link mt--2" onClick={reset} to={`${prefix}${ROUTES.showroom}`}><FormattedMessage id="back.showroom" /></Button>
              </Info>
            ) : null }

            { failed ? (
              <Info page visible close onClose={reset}>
                <h4 className="heading heading--4 fc--negative"><FormattedMessage id="contact.errortitle" /></h4>
                <p className="p--2"><FormattedMessage id="contact.errormessage" /></p>
              </Info>
            ) : null }
          </form>
        </div>

        { sending ? (<Loading page />) : null }
      </Page>
    </>
  );
}
